<template>
  <div>
    <vs-table max-items="10" :data="users">
      <template slot="thead">
        <vs-th>Can-ID</vs-th>
        <vs-th sort-key="candidate_name">Name</vs-th>
        <vs-th sort-key="batch">Books</vs-th>
        <vs-th sort-key="batch">OTB</vs-th>
        <vs-th sort-key="batch">Video</vs-th>
        <vs-th sort-key="recd">Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div>{{ tr.mwb.identity }}</div>
          </vs-td>
          <vs-td>
            <div>{{ tr.mwb.person_name }}</div>
          </vs-td>
          <vs-td>
            <div>
              <vs-chip
                transparent
                v-for="(subject, index) in getCategory(tr.deliverables, 'book')"
                :key="index"
                :color="getColordeli(subject)"
              >
                {{ subject.name }}
              </vs-chip>
            </div>
          </vs-td>

          <vs-td>
            <div>
              <!-- <vs-chip
                transparent
                v-for="(subject, index) in getCategory(tr.deliverables, 'otb')"
                :key="index"
                :color="getColordeli(subject)"
              >
                {{ subject.subject }}
              </vs-chip> -->
              <vs-chip
                transparent
                v-for="(subject, index) in tr.subject_access"
                :key="index"
                :color="getBadgeColor(subject.payment_status)"
              >
                {{ getBadgeName(subject.subject_id) }}
              </vs-chip>
            </div>
          </vs-td>
          <vs-td>
            <div>
              <!-- <vs-chip
                transparent
                v-for="(subject, index) in getCategory(
                  tr.deliverables,
                  'video_access'
                )"
                :key="index"
                :color="getColordeli(subject)"
              >
                {{ subject.subject }}
              </vs-chip> -->
              <vs-chip
                transparent
                v-for="(subject, index) in tr.subject_access"
                :key="index"
                :color="getBadgeColor(subject.payment_status)"
              >
                {{ getBadgeName(subject.subject_id) }}
              </vs-chip>
            </div>
          </vs-td>
          <vs-td>
            <vs-button
              v-if="tr.sendbuttoncolor === 'not_delivered'"
              style="margin-right:1%"
              size="small"
              color="success"
              @click="openpop(tr)"
              type="filled"
              >Send Books</vs-button
            >
            <vs-button
              v-if="tr.sendbuttoncolor === 'some_dispatched'"
              style="margin-right:1%"
              size="small"
              color="warning"
              @click="openpop(tr)"
              type="filled"
              >Send Books</vs-button
            >
            <div v-if="tr.sendbuttoncolor === 'all_delevered'">
              <div class="tooltip">
                <span
                  ><vs-chip color="success">
                    <vs-avatar icon="done" />
                    Books Sent
                  </vs-chip></span
                >
                <span class="tooltiptext">Books Sent</span>
              </div>
              <div class="tooltip">
                <span
                  ><vs-chip color="success">
                    <vs-avatar icon="done" />
                    Dispatched
                  </vs-chip></span
                >
                <span class="tooltiptext">Dispatched</span>
              </div>
            </div>
            <div v-if="tr.sendbuttoncolor === 'all_dispatched'">
              <div class="tooltip">
                <span
                  ><vs-chip color="success">
                    <vs-avatar icon="done" />
                    Books Sent
                  </vs-chip></span
                >
                <span class="tooltiptext">Books Sent</span>
              </div>
            </div>
            <vs-button
              v-if="
                tr.sendbuttoncolor === 'not_delivered' ||
                  tr.sendbuttoncolor === 'some_dispatched' ||
                  tr.sendbuttoncolor === 'all_dispatched'
              "
              size="small"
              color="danger"
              @click="opendeliverypopup(tr)"
              type="filled"
              >Delivery</vs-button
            >
            <!-- <vs-button
              v-if="selectedTab === 0"
              size="small"
              :disabled="
                getCategory(tr.deliverables, 'video_access').length === 0 &&
                  getCategory(tr.deliverables, 'otb').length === 0
              "
              :color="
                tr.purchases.video_otb_sent_status === 1 ? 'success' : 'danger'
              "
              @click="sendVideoOTB(tr)"
              type="filled"
              >Send Video and OTB</vs-button
            > -->

            <vs-button
              v-if="selectedTab === 0"
              size="small"
              style="margin-left:1%"
              :color="getColorLms(tr)"
              @click="editSubjectAccess(tr)"
              type="filled"
              >LMS Access</vs-button
            >

            <vs-button
              v-if="selectedTab === 1"
              size="small"
              :disabled="
                getCategory(tr.deliverables, 'video_access').length === 0 &&
                  getCategory(tr.deliverables, 'otb').length === 0
              "
              :color="
                tr.purchases.video_otb_sent_status === 1 ? 'success' : 'danger'
              "
              @click="openCMAWileyPopUp(tr)"
              type="filled"
              >Send Video and OTB</vs-button
            >
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-popup
      class="holamundo"
      title="Enter Wiley OTB codes"
      :active.sync="open"
    >
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6"
          ><b>WILEY PART 1 CODE</b></vs-col
        >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          ><vs-input class="inputx" placeholder="Part 2" v-model="wiley_1_code"
        /></vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6"
          ><b>WILEY PART 2 CODE</b></vs-col
        >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          ><vs-input class="inputx" placeholder="Part 1" v-model="wiley_2_code"
        /></vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button
            color="success"
            type="filled"
            @click="CMASendVideoOTB(CMAObjInfo)"
            >Send</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
    <!-- <warehouseDelivery></warehouseDelivery> -->
  </div>
</template>

<script>
import EventBus from "../components/eventbus.js";
import axios from "axios";
import constants from "../../constants.json";
// import eventbus from "../components/eventbus.js";
// import warehouseDelivery from "../components/pagesComponents/WarehouseDeliveryPopup.vue";
export default {
  props: {
    users: {
      type: Array,
      required: true,
    },
    selectedTab: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      CMAObjInfo: {},
      open: false,
      wiley_2_code: "",
      wiley_1_code: "",
      sendbookcolor: "success",
    };
  },
  mounted() {
    console.log(this.selectedTab);
  },
  components: {
    // warehouseDelivery
  },
  methods: {
    getColorLms(tr) {
      console.log(tr);
      if (tr.mwb.otb_id) {
        return "success";
      } else {
        return "danger";
      }
    },
    getBadgeName(subject) {
      let name = "";
      switch (subject) {
        case 101:
          name = "AUD";
          break;
        case 102:
          name = "BEC";
          break;
        case 103:
          name = "FAR";
          break;
        case 104:
          name = "REG";
          break;
      }
      return name;
    },
    getBadgeColor(payment) {
      let name = "";
      switch (payment) {
        case 0:
          name = "dark";
          break;
        case 1:
          name = "warning";
          break;
        case 2:
          name = "success";
          break;
      }
      return name;
    },
    openCMAWileyPopUp(Info) {
      this.wiley_1_code = "";
      this.wiley_2_code = "";
      this.CMAObjInfo = Info;
      this.open = true;
      console.log(Info);
    },
    getColordeli(deliverable) {
      let color = "";
      if (deliverable.delivery_status === "delivered") {
        color = "success";
      } else if (deliverable.dispatch_status === "to_be_dispatched") {
        color = "danger";
      } else if (deliverable.dispatch_status === "dispatched") {
        color = "warning";
      }
      return color;
    },
    getCategory(deliverables, type) {
      let list = [];
      deliverables.forEach((deliverable) => {
        if (deliverable.type === type) {
          list.push(deliverable);
        }
      });

      //  let listDeleverStatus = list.every( (val, i, arr) => val.delivery_status === 'delivered' && val.dispatch_status === 'dispatched')
      //  let listDispatchedStatus = list.some(deliverable =>  deliverable.delivery_status === "not_delivered" && deliverable.dispatch_status === 'dispatched')
      //  let listNotDeliveredStatus = list.every( (deliverable, i, arr) => deliverable.delivery_status === "not_delivered" && deliverable.dispatch_status === 'to_be_dispatched')

      // if(listDeleverStatus){
      //   this.sendbookcolor = 'primary'
      // }else if(listDispatchedStatus){
      //   this.sendbookcolor = 'warning'
      // }

      // console.log('listStatus',listStatus)
      return list;
    },
    editSubjectAccess(tr) {
      EventBus.$emit("open-warehouse-edit-popup", tr);
    },
    // getRole(providedRole) {
    //   if (localStorage.getItem("role") === providedRole) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    fetchData(page) {
      let params = {
        city: this.city,
        identity: "",
        page: page,
      };
      if (this.can_id !== "" && this.city === "") {
        this.$vs.notify({
          title: "Select a city to search with can Id",
          text: "You should provide a city to search with can Id",
          color: "danger ",
        });
        return;
      }

      if (this.can_id !== "" && this.city !== "") {
        params.city = "";
        params.identity = `${this.city.charAt(0).toUpperCase()}-${this.can_id}`;
      }

      console.log(params);
      this.sendRequest(params);
    },
    sendVideoOTB(obj) {
      console.log(obj);
      if (obj.enrollment === null) {
        this.$vs.notify({
          title: `No enrollment for this candidate`,
          text: "No enrollment was found for this candidate",
          color: "danger",
        });
        return;
      }
      if (obj.email === null) {
        this.$vs.notify({
          title: `No email for this candidate`,
          text: "No email was found for this candidate",
          color: "danger",
        });
        return;
      }
      let params = {
        subjects: [
          {
            video_status: this.getSendStatus(
              "video_access",
              "AUD",
              obj.deliverables
            ),
            mcq_status: this.getSendStatus("otb", "AUD", obj.deliverables),
            int_subject_id: 101,
          },
          {
            video_status: this.getSendStatus(
              "video_access",
              "BEC",
              obj.deliverables
            ),
            mcq_status: this.getSendStatus("otb", "BEC", obj.deliverables),
            int_subject_id: 102,
          },
          {
            video_status: this.getSendStatus(
              "video_access",
              "FAR",
              obj.deliverables
            ),
            mcq_status: this.getSendStatus("otb", "FAR", obj.deliverables),
            int_subject_id: 103,
          },
          {
            video_status: this.getRegStatus("video_access", obj.deliverables),
            mcq_status: this.getRegStatus("otb", obj.deliverables),
            int_subject_id: 104,
          },
        ],
        mwb_id: obj.mwb.id,
        email_id: obj.email.email,
        full_name: obj.mwb.person_name,
        batch: obj.enrollment.batch,
      };
      console.log(params);

      // https://lms-api-web.mileseducation.com/studentSubjectAcess

      let url = `${constants.SERVER_API}sendVideoAndOTBAccess`;
      axios
        .post(
          url,
          { data: params, purchase_id: obj.purchases.id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.$vs.notify({
            title: "Response",
            text: response.data.message,
            color:
              response.data.message === "Created Successfully"
                ? "success"
                : "danger",
          });
          EventBus.$emit("reload-current-page");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    CMASendVideoOTB(obj) {
      if (obj.enrollment === null) {
        this.$vs.notify({
          title: `No enrollment for this candidate`,
          text: "No enrollment was found for this candidate",
          color: "danger",
        });
        return;
      }
      if (obj.email === null) {
        this.$vs.notify({
          title: `No email for this candidate`,
          text: "No email was found for this candidate",
          color: "danger",
        });
        return;
      }
      let params = {
        subjects: [
          {
            video_status: this.getSendStatus(
              "video_access",
              "CMA Part 1",
              obj.deliverables
            ),
            mcq_status: 0,
            int_subject_id: 105,
          },
          {
            video_status: this.getSendStatus(
              "video_access",
              "CMA Part 2",
              obj.deliverables
            ),
            mcq_status: 0,
            int_subject_id: 106,
          },
        ],
        mwb_id: obj.mwb.id,
        part1_wileycode: this.wiley_1_code,
        part2_wileycode: this.wiley_2_code,
        email_id: obj.email.email,
        full_name: obj.mwb.person_name,
        batch: obj.enrollment.batch,
      };
      let url = `${constants.SERVER_API}sendVideoAndOTBAccessCMA`;
      axios
        .post(
          url,
          { data: params, purchase_id: obj.purchases.id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.$vs.notify({
            title: "Response",
            text: response.data.message,
            color:
              response.data.message === "Created Successfully"
                ? "success"
                : "danger",
          });
          EventBus.$emit("reload-current-page");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getRegStatus(type, deliverables) {
      let status = 0;
      deliverables.forEach((deliverable) => {
        if (
          (deliverable.subject === "REG 1" ||
            deliverable.subject === "REG 2") &&
          deliverable.type === type
        ) {
          status = 1;
        }
      });
      return status;
    },
    getSendStatus(type, subject, deliverables) {
      let status = 0;
      deliverables.forEach((deliverable) => {
        if (deliverable.subject === subject && deliverable.type === type) {
          status = 1;
        }
      });
      return status;
    },
    sendRequest(params) {
      let url = `${constants.SERVER_API}warehouseCustodianDashboardTest`;
      axios
        .get(url, {
          params: params,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.users = response.data.warehouse_data.data;
          this.totalpages = response.data.warehouse_data.last_page;
          this.currentpage = response.data.warehouse_data.current_page;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpoc() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.registeredSpoc = response.data.logged_in_user_id;
          this.extractSpocByCity(response.data.spocs);
          this.fetchData();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    extractSpocByCity(response) {
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      this.cities = [];
      // this.cities.push("All");
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.cities.push(key);
        }
      }
      this.cities = this.sortArrayAlphabetically(this.cities);
      // this.city = this.cities[0];
      this.fetchData();
    },
    opendeliverypopup(tr) {
      EventBus.$emit("open-warehouse-delivery-popup", tr);
    },
    openpop(warehouse) {
      EventBus.$emit("open-warehouse-popup", warehouse);
    },
    getColor(level) {
      switch (level) {
        case "pending":
          return "danger";
        case "sent":
          return "success";
        case "partially_sent":
          return "warning";
      }
    },
  },
};
</script>

<style></style>

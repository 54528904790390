<template>
  <div>
    <vs-row>
      <vs-col>
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="8"
          >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
          >
            <vs-dropdown vs-custom-content vs-trigger-click>
              <a class="a-icon" href.prevent @click="showTreeBox">
                SPOC
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="customDropDown">
                <treeselect
                  v-model="initSelectedBDE"
                  :multiple="true"
                  :options="treeDataBDE"
                />
              </vs-dropdown-menu>
            </vs-dropdown>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="3"
          >
          <vs-dropdown class="gap" vs-custom-content vs-trigger-click :key="dropdownKey">
              <vs-button
                radius
                color="dark"
                type="gradient"
                icon="search"
              ></vs-button>
              <vs-dropdown-menu class="loginx">
                <vs-row vs-w="12" style="margin-bottom: 3%">
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                  >
                    <vs-input type="text" placeholder="Name" v-model="value1" />
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-end"
                    vs-w="3"
                  >
                    <vs-button
                      color="dark"
                      type="gradient"
                      icon="search"
                      @click="fetchData()"
                    ></vs-button>
                  </vs-col>
                </vs-row>
                <vs-row vs-w="12" style="margin-bottom: 3%">
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="9"
                  >
                    <vs-input
                      type="text"
                      placeholder="Can-Id"
                      v-model="value5"
                    />
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-end"
                    vs-w="3"
                  >
                    <vs-button
                      color="dark"
                      type="gradient"
                      icon="search"
                      @click="fetchData()"
                    ></vs-button>
                  </vs-col>
                </vs-row>

                <vs-row vs-w="12" style="margin-bottom: 3%">
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="9"
                  >
                    <vs-input
                      type="text"
                      placeholder="Mobile"
                      v-model="value3"
                    />
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-end"
                    vs-w="3"
                  >
                    <vs-button
                      color="dark"
                      type="gradient"
                      icon="search"
                      @click="fetchData()"
                    ></vs-button>
                  </vs-col>
                </vs-row>

                <vs-row vs-w="12" style="margin-bottom: 3%">
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="9"
                  >
                    <vs-input
                      type="text"
                      placeholder="Email"
                      v-model="value4"
                    />
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-end"
                    vs-w="3"
                  >
                    <vs-button
                      color="dark"
                      type="gradient"
                      icon="search"
                      @click="fetchData()"
                    ></vs-button>
                  </vs-col>
                </vs-row>

                <vs-row vs-w="12" style="margin-bottom: 3%">
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="9"
                  >
                    <treeselect
                      v-model="book_info_status"
                      :options="status_list"
                      :placeholder="'Books Status'"
                    />
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-end"
                    vs-w="3"
                  >
                    <vs-button
                      color="dark"
                      type="gradient"
                      icon="search"
                      @click="fetchData()"
                    ></vs-button>
                  </vs-col>
                </vs-row>

                <vs-row vs-w="12" style="margin-bottom: 3%">
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="9"
                  >
                    <treeselect
                      v-model="video_status"
                      :options="status_list"
                      :placeholder="'Video Status'"
                    />
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-end"
                    vs-w="3"
                  >
                    <vs-button
                      color="dark"
                      type="gradient"
                      icon="search"
                      @click="fetchData()"
                    ></vs-button>
                  </vs-col>
                </vs-row>

                <vs-row vs-w="12" style="margin-bottom: 3%">
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="9"
                  >
                    <treeselect
                      v-model="otb_status"
                      :options="status_list"
                      :placeholder="'OTB Status'"
                    />
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-end"
                    vs-w="3"
                  >
                    <vs-button
                      color="dark"
                      type="gradient"
                      icon="search"
                      @click="fetchData()"
                    ></vs-button>
                  </vs-col>
                </vs-row>
              </vs-dropdown-menu>
            </vs-dropdown>
            <vs-button
              radius
              class="gap"
              color="dark"
              type="gradient"
              icon="refresh"
              @click="refreshdata"
            ></vs-button>
            <!-- <vs-button
              radius
              color="dark"
              type="gradient"
              @click="fetchData(1)"
              icon="search"
            ></vs-button> -->
          </vs-col>
        </vs-row>
        <vs-row style="margin-top: 2%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <b>Search Criteria:</b>
          </vs-col>
          <vs-col
            style="overflow-y: scroll"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="9"
          >
            <div v-for="(chip, index) in searchCriteria" :key="index">
              <vs-chip
                v-if="chip !== 'divider'"
                closable
                @click="removechip(chip)"
                color="primary"
              >
                <b>{{ chip }}</b>
              </vs-chip>
              <div v-else>
                <div class="vl"></div>
              </div>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              color="dark"
              type="gradient"
              icon="search"
              @click="fetchData()"
            ></vs-button>
          </vs-col>
        </vs-row>

        <vs-tabs v-model="selectedTab">
          <vs-tab label="CPA" value="cpa">
            <div class="con-tab-ejemplo">
              <warehouseTable
                :users="users"
                :selectedTab="selectedTab"
              ></warehouseTable>
            </div>
          </vs-tab>
          <vs-tab label="CMA" value="cma">
            <div class="con-tab-ejemplo">
              <warehouseTable
                :users="users"
                :selectedTab="selectedTab"
              ></warehouseTable>
            </div>
          </vs-tab>
        </vs-tabs>
        <vs-row style="margin-top: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-pagination
              :total="totalpages"
              v-model="currentpage"
            ></vs-pagination>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
    <warehouseDelivery></warehouseDelivery>
    <WarehouseSubjectEditPopup></WarehouseSubjectEditPopup>
  </div>
</template>
<script>
import _ from "lodash";
import warehouseDelivery from "../components/pagesComponents/WarehouseDeliveryPopup.vue";
import WarehouseSubjectEditPopup from "../components/pagesComponents/WarehouseSubjectEditPopup.vue";
import warehouseTable from "./WarehouseReportsTable.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import router from "@/router";
import EventBus from "../components/eventbus.js";
import axios from "axios";
import constants from "../../constants.json";
import vSelect from "vue-select";
export default {
  mounted() {
    this.getSpoc();
    EventBus.$on("reload-warehouse-custodian", () => {
      this.getSpoc();
    });
    EventBus.$on("reload-current-page", () => {
      this.fetchData(this.currentpage);
    });
  },
  components: {
    vSelect,
    router,
    warehouseTable,
    warehouseDelivery,
    WarehouseSubjectEditPopup,
    Treeselect,
  },
  data: () => ({
    dropdownKey:0,
    selectedTab: 0,
    city: "",
    cities: [],
    currentpage: 1,
    totalpages: 1,
    registeredSpoc: "",
    searchCriteria: [],
    book_status: "",
    books_status: ["Pending", "Sent"],
    can_id: "",
    spoc_names: [],
    mwb_names: [],
    status_list: [
      {
        id: "Pending",
        label: "Pending",
      },
      {
        id: "Sent",
        label: "Sent",
      },
      {
        id: "Delivered",
        label: "Delivered",
      },
    ],
    book_info_status: null,
    otb_status: null,
    video_status: null,
    loggedin: "",
    users: [],
    initSelectedBDE: [],
    treeDataBDE: [
      {
        id: "All",
        label: "All",
        children: [],
      },
    ],
    rawcities: [],
    rawBde: [],
    value1: "",
    value2: "",
    value3: "",
    value4: "",
    value5: "",
  }),
  watch: {
    selectedTab(value) {
      console.log(value);
      this.fetchData();
    },
    currentpage(value) {
      this.fetchData(value);
    },
    initSelectedBDE: function() {
      this.mergeAllSearch();
    },
  },
  methods: {
    showTreeBox() {
      return this.treeDataBDE;
    },
    refreshdata() {
      this.value1 = "";
      this.value2 = "";
      this.value3 = "";
      this.value4 = "";
      this.value5 = "";
      this.initSelectedBDE = [];
      this.fetchData();
    },
    getColordeli(deliverable) {
      let color = "";
      if (deliverable.delivery_status === "delivered") {
        color = "success";
      } else if (deliverable.dispatch_status === "to_be_dispatched") {
        color = "danger";
      } else if (deliverable.dispatch_status === "dispatched") {
        color = "warning";
      }
      return color;
    },
    getCategory(deliverables, type) {
      let list = [];
      deliverables.forEach((deliverable) => {
        if (deliverable.type === type) {
          list.push(deliverable);
        }
      });
      return list;
    },
    autoCompleteName(search, loading) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      this.fetchMwbs(loading, search, this);
    },
    fetchMwbs: _.debounce((loading, search, vm) => {
      let url = `${constants.SERVER_API}searchMwbName?name=${escape(search)}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          vm.mwb_names = response.data.map((lead) => lead.person_name);
          loading(false);
        });
    }, 350),
    // getRole(providedRole) {
    //   if (localStorage.getItem("role") === providedRole) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    getSelectedTab() {
      switch (this.selectedTab) {
        case 0:
          return "CPA";
        case 1:
          return "CMA";
        default:
          return "";
      }
    },
    fetchData(page) {
      this.dropdownKey = Math.random()
      let params = {
        city: this.city,
        identity: this.value5,
        selected_tab: this.getSelectedTab(),
        name: this.value1,
        spoc_ids: this.getSpocIds(),
        mobile: this.value3,
        email: this.value4,
        page: page,
        book_status: this.book_info_status,
        video_status: this.video_status,
        otb_status: this.otb_status,
      };
      // if (this.can_id !== "" && this.city === "") {
      //   this.$vs.notify({
      //     title: "Select a city to search with can Id",
      //     text: "You should provide a city to search with can Id",
      //     color: "danger "
      //   });
      //   return;
      // }

      // if (this.can_id !== "" && this.city !== "") {
      //   params.city = "";
      //   params.identity = `${this.city.charAt(0).toUpperCase()}-${this.can_id}`;
      // }

      console.log(params);
      this.sendRequest(params);
    },
    getSpocIds() {
      let bde = [];
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc"
      ) {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              bde.push(child.label);
            });
          } else {
            bde.push(sort);
          }
        });
      }
      let bdeid = [];
      bde.forEach((spoc) => {
        this.rawBde.forEach((raw) => {
          if (raw.first_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      return bdeid.join();
    },
    sendRequest(params) {
      let url = `${constants.SERVER_API}warehouseCustodianDashboardTest`;
      axios
        .get(url, {
          params: params,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "error") {
            this.handleNotification(response);
            return;
          }
          this.users = response.data.warehouse_data.data;
          console.log("users data",this.users)
          
          this.users.forEach(element => {
            let list = [];
            element.deliverables.forEach((deliverable) => {
              if (deliverable.type === 'book') {
                list.push(deliverable);
                let listDeleverStatus = list.every( (val) => val.delivery_status === 'delivered' && val.dispatch_status === 'dispatched')
                let listsomeDispatchedStatus = list.some(deliverable =>  deliverable.delivery_status === "not_delivered" && deliverable.dispatch_status === 'dispatched')
                let listAllDispatchedStatus = list.every( (deliverable) => deliverable.delivery_status === "not_delivered" && deliverable.dispatch_status === 'dispatched')
                let listNotDeliveredStatus = list.every( (deliverable) => deliverable.delivery_status === "not_delivered" && deliverable.dispatch_status === 'to_be_dispatched')

                if(listDeleverStatus){
                  element.sendbuttoncolor = 'all_delevered'
                }else if(listAllDispatchedStatus){
                  element.sendbuttoncolor = 'all_dispatched'
                }else if(listsomeDispatchedStatus){
                  element.sendbuttoncolor = 'some_dispatched'
                }else if(listNotDeliveredStatus){
                  element.sendbuttoncolor = 'not_delivered'
                }else{
                  element.sendbuttoncolor = 'no_books'
                }
              }
                    
            });
            
            
          });
          this.totalpages = response.data.warehouse_data.last_page;
          this.currentpage = response.data.warehouse_data.current_page;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpoc() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.registeredSpoc = response.data.logged_in_user_id;
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          this.extractSpocByCity(response);
          // this.fetchData();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    extractSpocByCity(response) {
      this.rawBde = response.data.spocs;
      this.treeDataBDE[0].children = [];
      this.rawcities = [];
      var unique = response.data.spocs
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.rawcities.push(key);
          var obj = {
            id: key,
            label: key,
            children: [],
          };
          this.treeDataBDE[0].children.push(obj);
        }
      }
      response.data.spocs.forEach((server_spoc) => {
        this.treeDataBDE.forEach((bde) => {
          bde.children.forEach((bdechid) => {
            if (bdechid.label === server_spoc.city) {
              var obj = {
                id: server_spoc.first_name,
                label: server_spoc.first_name,
              };
              bdechid.children.push(obj);
            }
          });
        });
      });
      this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
        this.treeDataBDE[0].children
      );
      if (this.rawBde.indexOf(this.searchIn) !== -1) {
        this.initSelectedBDE.push(this.searchIn.name);
      }
      // this.city = this.cities[0];
      this.fetchData();
    },
    mergeAllSearch() {
      this.searchCriteria = [];
      this.searchCriteria.push("divider");
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc"
      ) {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                this.searchCriteria.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  this.searchCriteria.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              this.searchCriteria.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              this.searchCriteria.push(child.label);
            });
          } else {
            this.searchCriteria.push(sort);
          }
        });
      }
      // this.searchCriteria.push("divider");
      // this.initSelectedCourses.forEach(sort => {
      //   if (sort === "All") {
      //     this.treeDataCourses[0].children.forEach(child => {
      //       this.searchCriteria.push(child.label);
      //     });
      //   } else {
      //     this.searchCriteria.push(sort);
      //   }
      // });
      // this.searchCriteria.push("divider");
      // this.initSelectedLevels.forEach(sort => {
      //   if (sort === "All") {
      //     this.treeDataLevels[0].children[0].children.forEach(child => {
      //       this.searchCriteria.push(child.label);
      //     });
      //     this.treeDataLevels[0].children[1].children.forEach(child => {
      //       this.searchCriteria.push(child.label);
      //     });
      //     this.treeDataLevels[0].children[2].children.forEach(child => {
      //       this.searchCriteria.push(child.label);
      //     });
      //   } else if (sort === "P1") {
      //     this.treeDataLevels[0].children[0].children.forEach(child => {
      //       this.searchCriteria.push(child.label);
      //     });
      //   } else if (sort === "P2") {
      //     this.treeDataLevels[0].children[1].children.forEach(child => {
      //       this.searchCriteria.push(child.label);
      //     });
      //   } else if (sort === "P3") {
      //     this.treeDataLevels[0].children[2].children.forEach(child => {
      //       this.searchCriteria.push(child.label);
      //     });
      //   } else {
      //     this.searchCriteria.push(sort);
      //   }
      // });
      // this.searchCriteria.push("divider");
      // if (this.initSelected !== "" && this.initSelected !== undefined) {
      //   this.searchCriteria.push(this.initSelected);
      // }
      // this.searchCriteria.push("divider");
      // this.infosearch.forEach(info => {
      //   this.searchCriteria.push(info);
      // });
    },
    removechip(chip) {
      // console.log(chip);
      // console.log(this.treeDataBDE);
      // console.log(this.treeDataLevels);
      // console.log(this.treeDataCourses);
      // console.log(this.treeDatafocus);
      // if (this.infosearch.indexOf(chip) !== -1) {
      //   this.infosearch.splice(this.infosearch.indexOf(chip), 1);
      // }
      let chipPlacement = "";
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc"
      ) {
        this.treeDataBDE[0].children.forEach((child) => {
          child.children.forEach((child2) => {
            if (child2.label === chip) {
              chipPlacement = "bde";
            }
          });
        });
      } else {
        this.treeDataBDE[0].children.forEach((child) => {
          if (child.label === chip) {
            chipPlacement = "bde";
          }
        });
      }
      /* this.treeDataCourses[0].children.forEach(child => {
        if (child.label === chip) {
          chipPlacement = "courses";
        }
      });
      this.treeDataLevels[0].children.forEach(child => {
        if (chip === "M7" || chip === "N/A" || chip === "DND") {
          chipPlacement = "levels";
        } else {
          if ("children" in child) {
            child.children.forEach(chi => {
              if (chi.label === chip) {
                chipPlacement = "levels";
              }
            });
          }
        }
      });
      if (this.initSelected === chip) {
        this.initSelected = undefined;
      } */
      switch (chipPlacement) {
        case "bde":
          if (this.initSelectedBDE.indexOf(chip) === -1) {
            if (
              localStorage.getItem("role") === "cm4" ||
              localStorage.getItem("role") === "wc"
            ) {
              if (this.initSelectedBDE.indexOf("All") !== -1) {
                this.initSelectedBDE = [];
                this.treeDataBDE[0].children.forEach((child) => {
                  child.children.forEach((child2) => {
                    if (child2.label !== chip) {
                      this.initSelectedBDE.push(child2.label);
                    }
                  });
                });
              } else {
                let city = "";
                this.treeDataBDE[0].children.forEach((child) => {
                  child.children.forEach((child2) => {
                    if (child2.label === chip) {
                      city = child.label;
                    }
                  });
                });
                this.initSelectedBDE.splice(
                  this.initSelectedBDE.indexOf(city),
                  1
                );
                this.treeDataBDE[0].children.forEach((child) => {
                  if (child.label === city) {
                    child.children.forEach((child2) => {
                      if (child2.label !== chip) {
                        this.initSelectedBDE.push(child2.label);
                      }
                    });
                  }
                });
              }
            } else {
              this.initSelectedBDE = [];
              this.treeDataBDE[0].children.forEach((child) => {
                if (child.label !== chip) {
                  this.initSelectedBDE.push(child.label);
                }
              });
            }
          } else {
            this.initSelectedBDE.splice(this.initSelectedBDE.indexOf(chip), 1);
          }
          break;
        // case "courses":
        //   if (this.initSelectedCourses.indexOf(chip) === -1) {
        //     this.initSelectedCourses = [];
        //     this.treeDataCourses[0].children.forEach(child => {
        //       if (child.label !== chip) {
        //         this.initSelectedCourses.push(child.label);
        //       }
        //     });
        //   } else {
        //     this.initSelectedCourses.splice(
        //       this.initSelectedCourses.indexOf(chip),
        //       1
        //     );
        //   }
        //   break;
        // case "levels":
        //   if (this.initSelectedLevels.indexOf("All") !== -1) {
        //     this.initSelectedLevels = [];
        //     this.treeDataLevels[0].children.forEach(child => {
        //       child.children.forEach(chi => {
        //         if (chi.label !== chip) {
        //           this.initSelectedLevels.push(chi.label);
        //         }
        //       });
        //     });
        //   } else if (this.initSelectedLevels.indexOf(chip) !== -1) {
        //     this.initSelectedLevels.splice(
        //       this.initSelectedLevels.indexOf(chip),
        //       1
        //     );
        //   } else {
        //     let childProperty = "";
        //     this.treeDataLevels[0].children.forEach(child => {
        //       if ("children" in child) {
        //         child.children.forEach(chi => {
        //           if (chi.label === chip) {
        //             childProperty = child.label;
        //           }
        //         });
        //       }
        //     });
        //     this.initSelectedLevels.splice(
        //       this.initSelectedLevels.indexOf(childProperty),
        //       1
        //     );
        //     this.treeDataLevels[0].children.forEach(child => {
        //       if (child.label === childProperty) {
        //         if ("children" in child) {
        //           child.children.forEach(chi => {
        //             if (chi.label !== chip) {
        //               this.initSelectedLevels.push(chi.label);
        //             }
        //           });
        //         }
        //       }
        //     });
        //   }
      }
    },
    opendeliverypopup(tr) {
      EventBus.$emit("open-warehouse-delivery-popup", tr);
    },
    openpop(warehouse) {
      EventBus.$emit("open-warehouse-popup", warehouse);
    },
    getColor(level) {
      switch (level) {
        case "pending":
          return "danger";
        case "sent":
          return "success";
        case "partially_sent":
          return "warning";
      }
    },
  },
};
</script>
<style>
/* @import "@/assets/scss/vuesax/pages/dropdown.scss"; */
.vs-table--tbody-table tr {
  font-family: sans-serif;
  /* font-size: smaller; */
}
.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}
.gap {
  margin-bottom: 4%;
}
.inicolor {
  color: gray;
}
.cardtext {
  color: gray;
  font-size: 20px;
}
.tree3 {
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px;
}
.vs-dropdown--menu {
  width: 300px;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gap {
  margin-right: 1%;
}
.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
i {
  font-size: 18px;
}
.vl {
  border-left: 1px solid black;
  height: 35px;
}
#style-1::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}
.scrollbar {
  overflow-y: scroll;
}
</style>

<template>
  <vs-popup title="EDIT SUBJECT ACCESS" :active.sync="open">
    <vs-card>
      <div class="centerx labelx" style="margin-top: 3%">
        <vs-divider>Subjects</vs-divider>
        <vs-row
          v-for="(subject, index) in subjects"
          :key="index"
          vs-w="12"
          style="margin-bottom: 3%"
        >
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <b style="color: gray; font-size: 12px"
              >{{ generateName(subject.subject) }} (
              {{ getCurrentStatus(subject.payment_status) }} )</b
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
          >
            <v-select
              :options="generateOptions(subject.payment_status)"
              v-model="subject.vModelStatus"
              label="year"
              style="width: 200px"
            />
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-divider>
              <vs-button color="warning" type="filled" @click="saveSettings()"
                >Save Settings</vs-button
              >
            </vs-divider>
          </vs-col>
        </vs-row>
      </div>
    </vs-card>
  </vs-popup>
</template>
<script>
import EventBus from "../eventbus.js";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../../constants.json";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      open: false,
      //  "Partial Access",
      options: ["Deactivate", "Full Access"],
      subjects: [],
      actual_payload: [],
    };
  },
  mounted() {
    EventBus.$on("open-warehouse-edit-popup", (payload) => {
      this.fetchSubjectAccess(payload);
      this.actual_payload = payload;
      this.open = true;
    });
  },
  methods: {
    fetchSubjectAccess(payload) {
      this.subjects = [];
      payload.subject_access.forEach((subjects) => {
        let obj = {
          subject: subjects.subject_id,
          vModelStatus: "",
          payment_status: subjects.payment_status,
        };
        this.subjects.push(obj);
      });
    },
    saveSettings() {
      let param_subjects = [];
      this.subjects.forEach((subject) => {
        let obj = {
          subject_id: subject.subject,
          value: 0,
        };

        if (subject.vModelStatus === "") {
          obj.value = subject.payment_status;
        } else {
          obj.value = this.retriveValue(subject.vModelStatus);
        }

        param_subjects.push(obj);
      });
      console.log(param_subjects);
      let params = {
        mwb_id: this.actual_payload.mwb.id,
        subjects: param_subjects,
      };

      let url = `${constants.SERVER_API}editSubjectAccess`;
      axios
        .post(url, params, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status !== "error") {
            EventBus.$emit("reload-warehouse-custodian");
            this.open = false;
          }
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    retriveValue(vModelStatus) {
      let name = "";
      switch (vModelStatus) {
        case "Deactivate":
          name = 0;
          break;
        case "Partial Access":
          name = 1;
          break;
        case "Full Access":
          name = 2;
          break;
      }
      return name;
    },
    generateName(subject) {
      let name = "";
      switch (subject) {
        case 101:
          name = "AUD";
          break;
        case 102:
          name = "BEC";
          break;
        case 103:
          name = "FAR";
          break;
        case 104:
          name = "REG";
          break;
      }
      return name;
    },
    generateOptions(payment_status) {
      let structure = [
        {
          name: "Deactivate",
          value: 0,
        },
        // {
        //   name: "Partial Access",
        //   value: 1,
        // },
        {
          name: "Full Access",
          value: 2,
        },
      ];

      let optionsArray = [];

      structure.forEach((option) => {
        if (option.value !== payment_status) {
          optionsArray.push(option.name);
        }
      });

      return optionsArray;
    },
    getCurrentStatus(payment) {
      let name = "";
      switch (payment) {
        case 0:
          name = "Deactivated";
          break;
        case 1:
          name = "Partial Access";
          break;
        case 2:
          name = "Complete Access";
          break;
      }
      return name;
    },
  },
};
</script>

<template>
  <vs-popup title="UPDATE DELIVERY STATUS" :active.sync="open">
    <vs-card>
      <div class="centerx labelx" style="margin-top: 3%">
        <vs-row
          vs-w="12"
          v-for="(book, index) in bookList"
          :key="index"
          style="margin-bottom: 3%"
        >
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <vs-checkbox
              :disabled="book.disabledState"
              v-model="book.selected"
              color="dark"
            >
              <b style="color: gray; font-size: 12px">{{ book.book }}</b>
            </vs-checkbox>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
          >
            <vs-input class="inputx" disabled v-model="book.courier_number" />
            <!-- <vs-input class="inputx" v-else v-model="book.courier_number" /> -->
            <!-- <v-select
              :disabled="book.disabledState"
              :options="options1"
              v-model="book.edition"
              label="year"
              style="width: 200px"
            /> -->
          </vs-col>
        </vs-row>
        <!-- <vs-row vs-w="12" style="margin-bottom: 2%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="6"
          >
            <b style="color: gray; font-size: 15px">Courier Number</b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
          >
            <vs-input
              v-if="courierDisable === false"
              class="inputx"
              v-model="courier"
            />
            <vs-input
              v-if="courierDisable === true"
              disabled
              class="inputx"
              v-model="courier"
            />
          </vs-col>
        </vs-row>
        <vs-row vs-w="12" style="margin-bottom: 2%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="6"
          >
            <b style="color: gray; font-size: 15px">Courier Company</b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
          >
            <vs-input
              v-if="courierDisable === false"
              class="inputx"
              v-model="courier_company"
            />
            <vs-input
              v-if="courierDisable === true"
              disabled
              class="inputx"
              v-model="courier_company"
            />
          </vs-col>
        </vs-row>
        <vs-row vs-w="12" style="margin-bottom: 2%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="6"
          >
            <b style="color: gray; font-size: 15px">Address</b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
          >
            <vs-textarea
              v-if="courierDisable === false"
              class="inputx"
              v-model="address"
            />
            <vs-textarea
              v-if="courierDisable === true"
              disabled
              class="inputx"
              v-model="address"
            />
          </vs-col>
        </vs-row> -->
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-divider>
              <vs-button color="success" type="filled" @click="sendDelivery()"
                >Delivered</vs-button
              >
            </vs-divider>
          </vs-col>
        </vs-row>
      </div>
    </vs-card>
  </vs-popup>
</template>
<script>
import EventBus from "../eventbus.js";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../../constants.json";

export default {
  components: {
    "v-select": vSelect
  },
  data() {
    return {
      bookList: [
        // { book: "AUD", edition: "", selected: false, disabledState: false },
        // { book: "FAR", edition: "", selected: false, disabledState: false },
        // { book: "BEC", edition: "", selected: false, disabledState: false },
        // { book: "REG", edition: "", selected: false, disabledState: false }
      ],
      select1Normal: "",
      mainData: {},
      courierNumbers: [],
      courierDisable: false,
      courier: "",
      select1: 1,
      select2: 1,
      select3: 1,
      select4: 1,
      checkBox1: false,
      checkBox2: false,
      checkBox3: false,
      checkBox4: false,
      options1: ["2019", "2020", "2021", "2022"],
      open: false,
      courier_company: "",
      address: ""
    };
  },
  mounted() {
    EventBus.$on("open-warehouse-delivery-popup", payload => {
      this.courierDisable = false;
      this.bookList = [];
      payload.deliverables.forEach(subject => {
        if (subject.dispatch_status === "dispatched") {
          let obj = {
            id: subject.id,
            book: subject.name,
            courier_number: subject.courier_number,
            selected: false,
            disabledState: false,
            preselected: false
          };
          this.bookList.push(obj);
        }
      });
      this.checkSentBooks(payload.deliverables);
      this.open = true;
    });
  },
  methods: {
    sendDelivery() {
      // if (this.courier === "" || this.courier_company === "") {
      //   this.$vs.notify({
      //     title: "Provide the courier details",
      //     text: "provide the courier details for the selected books",
      //     color: "danger"
      //   });
      //   return;
      // }
      let count = 0;
      this.bookList.forEach(book => {
        if (book.disabledState === false && book.selected === true) {
          count = count + 1;
        }
      });
      if (!count > 0) {
        this.$vs.notify({
          title: "Select atleast one subject",
          text: "Select atleast one subject to continue",
          color: "danger"
        });
        return;
      }
      this.sendDeliveryStatus();
    },
    sendDeliveryStatus() {
      let obj = {
        dispatch_ids: []
      };
      this.bookList.forEach(book => {
        if (!book.disabledState && book.selected) {
          obj.dispatch_ids.push(book.id);
        }
      });
      obj.dispatch_ids = obj.dispatch_ids.join();
      console.log(obj);
      this.sendRequest(obj);
    },
    sendRequest(paramObj) {
      let url = `${constants.SERVER_API}updateDeliveryStatus`;
      axios
        .post(url, paramObj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log(response);
          if (response.data.status !== "error") {
            EventBus.$emit("reload-warehouse-custodian");
            this.open = false;
          }
          this.handleNotification(response);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    checkSentBooks(deliverables) {
      console.log(deliverables);
      let count = 0;
      // deliverables.forEach((book, index) => {
      //   if (book.dispatch_status === "dispatched") {
      //     if (book.delivery_status === "delivered") {
      //       this.bookList[index].disabledState = true;
      //       this.bookList[index].selected = true;
      //       count = count + 1;
      //     }
      //   }
      // });
      this.bookList.forEach(book => {
        deliverables.forEach(deli_book => {
          if (book.id === deli_book.id) {
            if (deli_book.delivery_status === "delivered") {
              book.disabledState = true;
              book.selected = true;
              count = count + 1;
            }
          }
        });
      });
      if (count === deliverables.length) {
        this.courierDisable = true;
      }
    }
  }
};
</script>
